<template>
  <div class="exchange-wrap">
    <NavHeader title="我的礼券"></NavHeader>
    <div class="content">
      <div class='tab-list'>
        <div class="tab-item-wrap" @click="tapTap(item.type)" v-for="(item,index) in tabList" :key="index">
          <div class='tab-item' :class='item.type==status?"active":""'>{{item.name}}</div>
        </div>
      </div>
      <div class='warp'>
        <div class='container1'>

          <van-list class="coupon-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="flipOver" :immediate-check="false" :offset="50" v-if="!showNot">
            <div class="coupon-wrap" v-for="(item,index) in couponList" :key="index">
              <div class="top" @click="couponTap(item)">
                <div class="name">{{item.name}}</div>
                <div class="desc-wrap">
                  <div class="desc">{{item.desc}}</div>
                  <div class="qrcode iconfont iconerweima" v-if="item.state == 1"></div>
                </div>
              </div>
              <div class="bottom" @click="couponDetailsTap(item)">
                <div class="time">{{item.date_start}} 至 {{item.date_end}}</div>
                <div class="explain">
                  <span>查看详情</span>
                  <span class="iconfont iconxiangyou"></span>
                </div>
              </div>
              <div class="tip">
                <div v-if="item.state == 1 && item.isNew">
                  <img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/coupon/coupon-new.png" mode="widthFix" />
                </div>
                <div v-if="item.state == 2">
                  <img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/coupon/coupon-yishiyong.png" mode="widthFix" />
                </div>
                <div v-if="item.state == 3">
                  <img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/member/coupon/coupon-yishixiao.png" mode="widthFix" />
                </div>
              </div>
            </div>
          </van-list>
          <div class='not' v-if="showNot">
            <xy-not-tip message="暂无优惠券"></xy-not-tip>
          </div>

        </div>
      </div>

      <van-popup v-model="showcode" @close="closecode" position="bottom" :lazy-render="false" :close-on-popstate="true" class="popupWarp">

        <div class='codeWarp'>
          <div class='logoWarp'>
            <img src='https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/xiaoyuanshenghuo.png' />
          </div>
          <div class='close' @click='closecode'><span class='iconfont iconguanbi'></span></div>

          <div class='time'>二维码 <span>{{codeTime}}</span> 秒后自动刷新</div>

          <div class='code' id="myQrcode"></div>

          <div class='refresh' @click='refreshTap' v-if="clear">
            <span class='iconfont iconshuaxin1'></span>
            <span>点击刷新</span>
          </div>
          <div class='refresh' v-else>
            <span class='iconfont iconshuaxin1'></span>
            <span>刷新中</span>
          </div>

          <div class='title'>{{coupon.name}}</div>
          <div class='expirationDateWarp'>
            <span>有效期至:</span><span class='expirationDate'>{{coupon.date_end}}</span>
          </div>
          <div class='desc'>{{coupon.desc}}</div>
        </div>

      </van-popup>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NavHeader from '@/components/nav-header.vue'
import Footer from '@/components/foot2022.vue'
import utils from '@/common/utils'
import XyNotTip from '@/components/xy-not-tip.vue'
import QRCode from 'qrcodejs2'
import md5 from 'md5'

export default {
  name:"couponList",
  data () {
    return {
      tabList: [
        { name: "可使用", type: 1 },
        { name: "已使用", type: 2 },
        { name: "已失效", type: 3 }
      ],
      status: 1,

      coupon: {},
      couponList: [],
      per_page: 10,
      page: 1,
      loading: false,
      finished: false,
      showNot: false,

      showcode: false,
      appCount: '',
      myQrcode:null,
      clear: true,
      codeTime: 60,


    }
  },
  components: {
    NavHeader,
    Footer,
    XyNotTip
  },
  computed: {
    ...mapState(['user_info', 'ocApi']),
  },

  created () {
    this.getCouponList();
    this.$store.dispatch('updateMember')
  },
  methods: {
    getCouponList: function () {
      let that = this;
      let userinfo = that.user_info;
      let status = that.status;
      let page = that.page;
      let per_page = that.per_page;
      that.$axios.post(that.ocApi + 'coupon/index', {
        telephone: userinfo.telephone,
        status,
        page,
        per_page,
      })
        .then(res => {
          if (res.data.current_page >= res.data.last_page) {
            that.finished = true
          }
          if (res.data.total == 0) {
            that.showNot = true
            return;
          }
          let couponListData = res.data.data;
          let ts = Math.round(new Date().getTime() / 1000);
          for (const item of couponListData) {
            if (parseInt(ts) - parseInt(item.fetch_time) < (1 * 24 * 60 * 60)) {
              item.isNew = true
            } else {
              item.isNew = false
            }
          }
          let couponList = that.couponList;
          couponList.push(...couponListData)
          that.couponList = couponList
          that.loading = false
        })
        .catch(error => {
          window.console.log(error)
        });
    },
    flipOver: function () {
      this.page += 1
      this.getCouponList()
    },
    tapTap: utils.debounce(function (status) {
      this.status = status
      this.coupon = {}
      this.couponList = []
      this.page = 1
      this.finished = false
      this.showNot = false
      this.getCouponList();
    }, 200, true),
    couponDetailsTap: utils.debounce(function () {

    }, 200, true),
    couponTap: utils.debounce(function (item) {
      this.coupon = item;
      this.showcode = true;
      this.getCode();
    }, 200, true),
    closecode: utils.debounce(function () {
      this.coupon = {};
      this.showcode = false;
      clearInterval(this.appCount);
    }, 200, true),
    refreshTap: utils.debounce(function () {
      clearInterval(this.appCount);
      this.getCode();
    }, 200, true),
    getCode: function () {
      var that = this;
      let code = that.coupon.record_code;
      let time = new Date().getTime();
      let hash = JSON.stringify({
        hash: md5(md5(code + '' + time)),
        code,
        time
      })
      that.clear = false
      that.codeTime = 60
      if (that.myQrcode instanceof QRCode) {
        that.myQrcode.makeCode(hash)
      } else {
        that.myQrcode = new QRCode('myQrcode', {
          width: 155,
          height: 155,
          text: hash, // 二维码地址
          colorDark: "#000",
          colorLight: "#fff",
        })
      }
      setTimeout(function () {
        that.appCount = setInterval(function () {
          that.codeTime = that.codeTime - 1
          if (that.codeTime == 0) {
            clearInterval(that.appCount);
            //取消指定的setInterval函数将要执行的代码 
            that.getCode();
          }
        }, 1000)
        that.clear = true
      }, 300)

    },

  },
}
</script>

<style lang="scss" scoped>
.content {
  background-color: #f7f7f7;
  min-height: 400px;
  padding-top: 46px;
}
.tab-list {
  display: flex;
  height: 100%;
  justify-content: center;
  background: #fff;
  font-size: 14px;
  position: fixed;
  width: 100%;
  z-index: 200;
  height: 40px;
}
.tab-item-wrap {
  flex: 1;
  display: flex;
  height: 100%;
  justify-content: center;
}
.tab-item {
  height: 100%;
  line-height: 40px;
  margin: 0 19px;
  box-sizing: border-box;
}
.tab-item.active {
  color: #000000;
  border-bottom: 2px solid #000000;
}

.warp {
  width: 100%;
  background-color: #f7f7f7;
  color: #434343;
  padding-bottom: 20px;
  margin-top: 40px;
}

.container1 {
  padding: 0 17px;
  box-sizing: border-box;
  font-size: 14px;
}
.coupon-list {
  padding-top: 26px;
}
.coupon-wrap {
  margin-bottom: 26px;
  background-color: #fff;
  padding: 0 17px;
  box-shadow: 0px 3px 5px 0px rgba(221, 221, 221, 0.34);
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}
.coupon-wrap::after {
  content: ' ';
  width: 11px;
  height: 11px;
  border-radius: 100%;
  background-color: #f7f7f7;
  position: absolute;
  left: -6px;
  top: 77px;
}
.coupon-wrap::before {
  content: ' ';
  width: 11px;
  height: 11px;
  border-radius: 100%;
  background-color: #f7f7f7;
  position: absolute;
  right: -6px;
  top: 77px;
}
.coupon-wrap .top {
  padding: 25px 0 6px;
}
.coupon-wrap .top .name {
  font-size: 16px;
  font-weight: bold;
  color: #393a3c;
  line-height: 16px;
}
.coupon-wrap .top .desc-wrap {
  margin-top: 12px;
  display: flex;
}
.coupon-wrap .top .desc-wrap .desc {
  font-size: 12px;
  color: #646567;
  flex: 1;
}
.coupon-wrap .top .desc-wrap .qrcode {
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: #eaeaea;
}
.coupon-wrap .bottom {
  padding: 14px 0;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #646568;
  border-top: 1px dashed #eaeaea;
}
.coupon-wrap .bottom .explain .iconfont {
  margin-left: 3px;
  font-size: 12px;
}
.coupon-wrap .tip {
  width: 47px;
  height: 47px;
  position: absolute;
  right: 0;
  top: 0;
}
.coupon-wrap .tip img {
  width: 100%;
}

.popupWarp.van-popup {
  overflow-y: initial;
  border-radius: 7px 7px 0 0;
}
.codeWarp {
  width: 100%;
  background-color: #fff;
  position: relative;
  border-radius: 7px 7px 0 0;
  padding: 0 16px;
  box-sizing: border-box;
}
.codeWarp .logoWarp {
  width: 85px;
  height: 85px;
  border-radius: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: absolute;
  top: -25px;
  left: 50%;
  margin-left: -42px;
  z-index: 200;
}
.codeWarp .logoWarp img {
  width: 78px;
  height: 78px;
  border-radius: 78px;
}
.codeWarp .close {
  padding-top: 15px;
  text-align: right;
}
.codeWarp .close .iconfont {
  font-size: 16px;
  color: #dddddd;
}
.codeWarp .time {
  margin-top: 46px;
  color: #999999;
  font-size: 14px;
  text-align: center;
}
.codeWarp .code {
  margin-top: 19px;
  display: flex;
  justify-content: center;
}
.codeWarp .refresh {
  margin-top: 15px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00c470;
  border-bottom: 1px dotted #ececec;
  padding-bottom: 18px;
}
.codeWarp .refresh .iconfont {
  font-size: 14px;
  margin-right: 4px;
}

.codeWarp .title {
  font-size: 19px;
  color: #434343;
  margin-top: 18px;
  text-align: center;
}
.codeWarp .expirationDateWarp {
  padding: 0 50px;
  box-sizing: border-box;
  color: #999999;
  font-size: 13px;
  margin-top: 12px;
  line-height: 13px;
}
.codeWarp .expirationDateWarp .expirationDate {
  color: #434343;
  margin-left: 3px;
}
.codeWarp .desc {
  color: #999999;
  font-size: 13px;
  margin-bottom: 40px;
  padding: 0 50px;
  box-sizing: border-box;
  margin-top: 8px;
  line-height: 13px;
}
</style>